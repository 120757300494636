import React from 'react'
import { Link } from 'react-router-dom'

function Contact() {
  return (
    <div>
        <div className='container-fluid backgclr'>
            <div className='container contact'>
                <div className='row'>
                <h2>CONTACT US</h2>
                <div className='row '>
                    <div className='contactno'>
                        <h5 className='row con'><div className='col-3'>Contact No </div><div className='col-1'>: </div><div className='col-8'><span><img src="images/phone.png"/>+91 9314267002</span></div></h5>
                    </div>
                    <div className='contactno'>
                        <h5 className='row con'><div className='col-3'>Email</div><div className='col-1'>: </div><div className='col-8'><span><img src="images/mail.png"/>info@devhubtech.in</span></div></h5>
                    </div>
                    <div className='contactno'>
                        <h5 className='row con'><div className='col-3'>Address</div><div className='col-1'>: </div><div className='col-8'><span><img src="images/location.png"/>103 Pashupati Nath Nagar, Jaipur</span></div></h5>
                    </div>
                     <div className=''>
                      <div className='skype'><a href="https://join.skype.com/invite/s82cNSJZ5wcU"><img src="images/skype.PNG"/></a></div>
                     </div>
                     <div className='condition'>
                     <ul>
                     <li><Link to="/privacy">Privacy Policy </Link></li>
                     <li><Link to="/terms">Terms & Conditions</Link></li>
                     </ul>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Contact