import React from 'react'
import Features from './Features'
import Ludo from './Ludo'
import Demo from './Demo'
import Footer from '../Layout/Footer'
import Contact from './Contact'

function Homecomponent() {
  return (
    <div className=''>
     <Demo/>
    <Ludo/>
     <Features/>
     <Contact/>
     <Footer/>
        {/* <Gameimg/> */}
    </div>
  )
}

export default Homecomponent