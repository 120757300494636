import React from 'react'
import Footer from '../Layout/Footer'
import Demo from './Demo'

function Terms() {
  return (
    <div>
    <Demo />
    <iframe title="Embedded HTML File" src="/terms.html" width="100%" height="600" />
    <Footer />
    </div>
  )
}

export default Terms