import React from 'react'

function Footer() {
  return (
    <div>
    <div className='container-fluid footer'>
        <div className='container'>
        <div className='footeruserdetail'>
            <div className='footerdemo1 row'>
             <div className='row footerlist'>
               <div className='row'>
                  <div className='footerdemoimg blinking-image col-3'><p>Demo Link<img src="images/arrow.png"/></p></div>
                   <div className='col-3 b3'><a href="https://play.google.com/store/apps/details?id=lxludo.com&hl=en-IN"><button><img src="images/playstore.png"></img></button></a></div>
                   <div className='col-3 b2'><a href="https://ludodemo.jaipurcomputerclasses.com/login"><button><p><img src="images/web.png"></img>Website</p></button></a></div>
                   <div className='col-3 b1'><a href="https://ludodemoadmin.jaipurcomputerclasses.com/"><button><img src="images/user.PNG"/>Admin</button></a></div>
                 </div>
              </div>
          </div>
          </div>
          </div>
       </div>
   </div>
        
 
  )
}

export default Footer