import React from 'react'
import Slider from 'react-slick';

function Ludo() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true, 
        autoplaySpeed: 3000, 
      };
      
      const customButtonStyle = {
        color: 'black !important', // Change the color to black
      };
  return (
    <div className='backgclr'>
    <div className='container-fluid features_content'>
        <div className='container myludo'>
            <div className="row ludo">
          
            <div className="callludo"><h5><span >Call <span className='callarrow'>&#9758;</span></span><img src="images/phone.png" />+91 9314267002</h5></div>

                <div className='col-3 ludo1'>
                      <img src="images/b.png"></img>
                </div>


                <div className='col-3 ludo1'>
                      <img src="images/a.png"></img>
                </div>


                <div className='col-3 ludo1'>
                     <img src="images/d.png"></img>
                </div>

                <div className='col-3 ludo1'>
                <img src="images/e.png"></img>
                 </div>
                
                <div className='col-3 ludo1'>
                   <img src="images/f.png"></img>
                </div>
                
                
                <div className='col-3 ludo1'>
                     <img src="images/g.png"></img>
                </div>
                
                <div className='col-3 ludo1'>
                   <img src="images/h.png"></img>
               </div>

                
                <div className='col-3 ludo1'>
                    <img src="images/i.png"></img>
                </div>
                

                <div className='col-3 ludo1'>
                    <img src="images/j.png"></img>
                </div>


                <div className='col-3 ludo1'>
                     <img src="images/k.png"></img>
                </div>


                <div className='col-3 ludo1'>
                      <img src="images/l.png"></img>
                </div>
            


                <div className='col-3 ludo1'>
                      <img src="images/m.png"></img>
                </div>



                <div className='col-3 ludo1'>
                      <img src="images/n.png"></img>
                </div>


                <div className='col-3 ludo1'>
                    <img src="images/o.png"></img>
               </div>
           


                <div className='col-3 ludo1'>
                      <img src="images/p.png"></img>
                </div>

            </div>
        </div>
    </div>
    <div className='sliderimage'>
    <div className="call"><h5>Call<img src="images/phone.png"/>+91 9314267002</h5></div>
    <div>
        
        <Slider {...settings}>
       
          <div>
          <img src="images/b.png"></img>
          </div>
     
          <div>
          <img src="images/d.png"></img>
          </div>
          <div>
          <img src="images/e.png"></img>
          </div>
           <div>
          <img src="images/f.png"></img>
          </div>
          <div>
          <img src="images/g.png"></img>
          </div>
          <div>
          <img src="images/h.png"></img>
          </div>
          <div>
          <img src="images/i.png"></img>
          </div>
          <div>
          <img src="images/j.png"></img>
          </div>
          <div>
          <img src="images/k.png"></img>
          </div>
          <div>
          <img src="images/l.png"></img>
          </div>
          <div>
          <img src="images/m.png"></img>
          </div>
          <div>
          <img src="images/n.png"></img>
          </div>
          <div>
          <img src="images/o.png"></img>
          </div>
          <div>
          <img src="images/p.png"></img>
          </div>
        
        </Slider>
      </div>
    </div>
   </div>
  )
}

export default Ludo