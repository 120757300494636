import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "../Component/Home/Home";
import Privacy from "../Component/Home/Privacy";
import Terms from "../Component/Home/Terms";

function AppRoute() {
  return (
    <BrowserRouter>
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/terms" element={<Terms />} />
        
      </Routes>
    </BrowserRouter>
  );
}

export default AppRoute;
