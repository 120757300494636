import logo from "./logo.svg";
import "./App.css";
import Home from "./Component/Home/Home";
import AOS from "aos";
import "bootstrap/dist/css/bootstrap.min.css";

import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import toast, { Toaster } from "react-hot-toast";
import AppRoute from "./Route/AppRoute";
import FloatingWhatsApp from "react-floating-whatsapp";
import { useEffect } from "react";

function App() {

  useEffect(() => {
    AOS.init(); // Initialize AOS

    // Manually refresh AOS after the slides have been rendered
    AOS.refresh();
  }, []);
  return (
    <div>
      <AppRoute />
      <Toaster />
      <div>
     

      
      </div>
    </div>
  );
}

export default App;
