import React from 'react'
import Demo from './Demo'
import Footer from '../Layout/Footer'

function Privacy() {

  return (
    <div> 
    <Demo />
     <iframe title="Embedded HTML File" src="/privacy.html" width="100%" height="600" />
    <Footer />
  </div>
  )
}

export default Privacy