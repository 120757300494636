import React from 'react'

function Features() {
  return (
    <div className='backgclr'>
    <div className='container-fluid features_content'>
        <div className='container'>
        <div className='row Features'>
        <h3>Features</h3>
        <p><span className='arrowdown'>&#x1F447;</span></p>
          <ul>
          <h5>Creating Accounting LUDO KING PLAY –</h5>
            <li>User Visit LUDO KING PLAY App</li>
            <li>Register Himself with Phone Number</li>            
            <li>Creates his Account After OTP Verification.</li>

        
          <h5>Creating Battle in LUDO KING PLAY –</h5>
            <li>First Player – First Player Creates Battle and wait second Player to join.</li>
            <li>Battle Amount can be 25 – 25000 rupees</li>            
            <li>Second Player – Second Player Have List of Battels and he Joins Created Battle.</li>
            <li>First Player - After Second Player Join Battle First Player Starts Battle.</li>
            <li>User Can Enjoy Game on Ludo King.</li>
            <li>After Battle Starts Room Code Will be Given</li>
         
          <h5>Winner Selection in LUDO KING PLAY –</h5>
            <li>After Game Play Both User Will Take Screenshot and Upload on LUDO KING PLAY.</li>
            <h5>Case 1: (First User Winner Second User Looser)</h5>
            <li>If first user is winner, he will upload screenshot on winner section.</li>
            <h5>Case 2: (Both Are Winner)</h5>            
            <li>If both users are winner and both are uploading screenshot on winner section then admin will decide who is the winner.</li>
            <li>If user upload a wrong screenshot will face penalties and there will be consequences for the user." </li>
            <h5>Case 3: (Both Are Looser)</h5>
            <li>If both users are Loose the game section, then amount is not refundable.</li>
            <h5>Payment and Charges –</h5>
            <li>If User Wants to Create battle or want to play, he First need to Recharge is Wallet.</li>
            <li>Every Time Battle Amount Deducted from Wallet.</li>
            <li>Admin Can Deduct some Percentage of battle Game (Dynamic Amount)</li>
            <li>Admin Can Deduct Some Percentage Amount on </li>
            <h5>Withdrawal Also Notification –</h5>
            <li>Admin Can Send Notification for Updates and Stuffs.</li>
            <h5>Kyc –</h5>
            <li>User Need to Upload Document for Any Kind of Payment Transactions.</li>
            <li>Admin Will Manually Check and Approve user After Kyc..</li>
            <li>After Kyc Approval User Can Play and Create Battle.</li>
            <h5>User Winning Amount –</h5>
            <li>After User Wins the game, User Will Manually Apply for Amount on Payment Section</li>
            <li>After Admin Will Clear his Payment.</li>
            <h5>Wallet</h5>
            <li>Recharge Wallet</li>
            <li>Winning Wallet</li>
            <li>Earning Wallet</li>
            <h5>Game Terms & Condition –</h5>
            <li>If user mislead admin putting wrong screenshot for Every Wrong Deceleration 50 Rupees Penalty Amount will be educated or admin can ban user for that.</li>
            <h5>Refer and earn –</h5>
            <li>If Any of User Refer the app to someone, he Will get Some amount from admin (Dynamic Amount)</li>
          </ul>
        </div>
            {/* <div className="row Features">
                <div className='col-2 Features1'>
                <div className='inner_div '>
                <h3><img src="images/c.png"></img>Features</h3>
                <p>Real Cash Prizes</p>
                <p>Multiplayer Gameplay</p>
                <p>Secure Transactions</p>
                <p>User Wallet</p>
                <p>User Profiles</p>
                <p>Responsive Website</p>
                </div>
            </div>

            <div className='col-10 Features1'>
                <div className='inner_div '>
                <h3><img src="images/c.png"></img>Technologies</h3>
                <table>
  <tr>
    <th>Programming Languages</th>
     <th>Firestore</th>
    <th>Payment Integrationy</th>
    <th>User Authentication</th>
    <th>Web Hosting</th>
    <th>Version Control</th>
    <th>Development Environments</th>
  </tr>
  <tr>
    <td> Java/Kotlin (for Android app), HTML, CSS, JavaScript</td>
    <td>Firebase</td>
    <td>Secure payment gateway integration for financial transactions</td>
    <td>Firebase Authentication</td>
    <td>Hosting platform</td>
    <td>Git</td>
    <td>Android Studio (for app), Code editors (for website)</td>
  </tr>
 
</table>
</div>
</div>
</div> */}
</div>
</div>
</div>
  )
}

export default Features